import React from 'react'
import { Container, Row } from 'reactstrap'
import styled from 'styled-components'
import logo from './../../assets/img/PremiosLogra-Carrusel.jpg'

const ImgLogra = styled.img`
  display: block;
  height: 420px;
  margin: 50px 0 0 0;

  @media (min-width: 56px) and (max-width: 574px) {
    height: 130px !important;
    margin: 26px 0 39px 0;
  }

  @media (min-width: 576px) and (max-width: 991px) {
    height: 250px !important;
    margin: 26px 0 39px 0;
  }
  @media (min-width: 992px) and (max-width: 1400px) {
    height: 300px !important;
  }
`
const HomeTitleColabora2024 = () => {
  return (
    <Container className=' animate__animated animate__fadeIn animate__faster'>
      <Row className='home-row-ods-img'>
        <div className='home-ods-svg-div2'>
          <ImgLogra src={logo} />
        </div>
      </Row>
    </Container>
  )
}

export default HomeTitleColabora2024
